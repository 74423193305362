import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import IntlProvider from '@shared/IntlProvider';
import { ModalProvider } from '@src/context/modalContext';
// import RuntimeError from '@src/components/pages/RuntimeError';
import GlobalStyles from '@src/styles/globalStyles';
import ResetStyles from '@src/styles/ResetStyles';
import theme from '@src/styles/theme';

import 'react-loading-skeleton/dist/skeleton.css';

const queryClient = new QueryClient();

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    // Init PostHog
    if (process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
        api_host: 'https://app.posthog.com',
      });

      // Track page views
      const handleRouteChange = () => posthog.capture('$pageview');
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, []);

  return (
    <Sentry.ErrorBoundary
    // fallback={<RuntimeError />}
    >
      <Head>
        <title>Sammy</title>
        <meta
          content="U.S. Banking without borders | Sammy"
          property="og:title"
        />
        <meta
          name="description"
          content="Sammy is the first US-based bank account designed to help international workers get paid faster from the United States, save their money in dollars and spend with an international Mastercard."
        />
        <meta
          property="og:description"
          content="Sammy is the first US-based bank account designed to help international workers get paid faster from the United States, save their money in dollars and spend with an international Mastercard."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <IntlProvider>
        <ThemeProvider theme={theme}>
          <ResetStyles />
          <GlobalStyles />
          <ModalProvider>
            <QueryClientProvider client={queryClient}>
              <Component {...pageProps} />
            </QueryClientProvider>
          </ModalProvider>
        </ThemeProvider>
      </IntlProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
