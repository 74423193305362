import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    surface: {
      color: {
        primary: '#FBE274',
        secondary: '#B6DBD0',
        tertiary: '#BEC7E7',
      },
      dark: {
        primary: '#000',
        secondary: '#3F3E3E',
        tertiary: 'rgba(63, 62, 62, 0.5)',
      },
      light: {
        primary: '#FFF',
        secondary: '#EEEDE7',
        tertiary: 'rgba(238, 237, 231, 0.5)',
      },
      danger: {
        primary: '#D045261A',
        secondary: '#D045261A',
        tertiary: '#D045261A',
      },
      success: {
        primary: '#2DAA0E',
        secondary: '#2DAA0E1A',
        tertiary: '#2DAA0E1A',
      },
    },
    content: {
      color: {
        primary: '#000',
        secondary: '#000',
        tertiary: '#000',
      },
      dark: {
        primary: '#fff',
        secondary: '#fff',
        tertiary: 'rgba(255, 255, 255, 0.5)',
      },
      light: {
        primary: '#000',
        secondary: 'rgba(0, 0, 0, 0.7)',
        tertiary: 'rgba(0, 0, 0, 0.5)',
      },
      danger: {
        primary: '#D04526',
        secondary: '#D04526',
        tertiary: '#D04526',
      },
      success: {
        primary: '#2DAA0E',
        secondary: '#2DAA0E1A',
        tertiary: '#2DAA0E1A',
      },
      info: {
        primary: '#3E68FF',
        secondary: '#3E68FF',
        tertiary: '#3E68FF',
      },
    },
    border: {
      dark: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.5)',
        tertiary: 'rgba(255, 255, 255, 0.1)',
      },
      light: {
        primary: '#000000',
        secondary: 'rgba(0, 0, 0, 0.5)',
        tertiary: 'rgba(0, 0, 0, 0.1)',
      },
    },
    primary: {
      yellow: '#FBE274',
      green: '#B6DBD0',
      blue: '#BEC7E7',
    },
    feedback: {
      red: '#D04526',
      green: '#2DAA0E',
      info: '#3E68FF',
    },
  },
  spacing: {
    0: 0,
    px: 1,
    0.5: 2,
    1: 4,
    1.5: 6,
    2: 8,
    2.5: 10,
    3: 12,
    3.5: 14,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    11: 44,
    12: 48,
    13: 52,
    14: 56,
    15: 60,
    16: 64,
    18: 72,
    19: 76,
    20: 80,
    24: 96,
    26: 104,
    28: 112,
    32: 128,
    36: 144,
    40: 160,
    42: 168,
    44: 176,
    46: 184,
    48: 192,
    52: 208,
    56: 224,
    64: 256,
    72: 288,
    80: 320,
    96: 384,
    112: 448,
    128: 512,
    140: 560,
  },
  fontFamily: {
    display: 'ClashDisplay, sans-serif',
    sans: 'Roobert, sans-serif',
  },
  zIndex: {
    '0': 0,
    '10': 10,
    '20': 20,
    '30': 30,
    '40': 40,
    '50': 50,
  },
};

export default theme;
