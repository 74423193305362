import { IntlProvider as RIIntlProvider } from 'react-intl';

import en from '@src/locales/en.json';
import es from '@src/locales/en.json';

export const locale = 'en';
const translations = { en, es };
const messages = translations[locale];

interface IntlProviderProps {
  children: React.ReactNode;
}

function IntlProvider({ children }: IntlProviderProps) {
  return (
    <RIIntlProvider messages={messages} locale={locale} defaultLocale="en">
      {children}
    </RIIntlProvider>
  );
}

export default IntlProvider;
