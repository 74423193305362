import React from 'react';
import { createGlobalStyle } from 'styled-components';

const DefaultStyles = createGlobalStyle`
    @font-face {
      font-family: 'ClashDisplay-Regular';
      src: url('/fonts/ClashDisplay-Regular.otf');
      font-style: normal;
      font-weight: 300;
    }

    @font-face {
      font-family: 'ClashDisplay-Medium';
      src: url('/fonts/ClashDisplay-Medium.otf');
      font-style: normal;
      font-weight: 400;
    }
    @font-face {
      font-family: 'ClashDisplay-Semibold';
      src: url('/fonts/ClashDisplay-Semibold.otf');
      font-style: bold;
      font-weight: 500;
    }

    @font-face {
      font-family: Roobert;
      src: url('/fonts/Roobert-Regular.otf');
      font-style: normal;
      font-weight: 300;
    }

    @font-face {
      font-family: Roobert;
      src: url('/fonts/Roobert-Medium.otf');
      font-style: normal;
      font-weight: 400;
    }

    @font-face {
      font-family: Roobert;
      src: url('/fonts/Roobert-SemiBold.otf');
      font-style: bold;
      font-weight: 500;
    }

  ::-moz-selection { 
      background: ${({ theme }) => theme.colors.surface.color.primary};
  }
   ::selection { 
      background: ${({ theme }) => theme.colors.surface.color.primary};
  }

   html {
    background-color: ${({ theme }) => theme.colors.surface.dark.primary};
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.fontFamily.sans};
   }
`;

function GlobalStyles() {
  return <DefaultStyles />;
}

export default GlobalStyles;
