import { createGlobalStyle } from 'styled-components';

const DefaultStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  html, body, #__next {
    height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  #root, #__next {
    isolation: isolate;
  }
`;

function ResetStyles() {
  return <DefaultStyles />;
}

export default ResetStyles;
